import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import LinkOrHref from './LinkOrHref'

const Feature = ({ data }) => {
  const { title, ctaText, ctaUrl } = data
  const image = getImage(data.image.gatsbyImage)

  return (
    <div className="feature">
      <div className="content">
        <h2 className="title my-10">
          <span>{title}</span>
        </h2>

        <LinkOrHref url={ctaUrl}>
          <button className="cta text-white p-4 hover:text-slate-600 hover:bg-slate-100 transition duration-300 ">
            {ctaText}
          </button>
        </LinkOrHref>
      </div>
      <div className="image">
        <GatsbyImage image={image} alt={title} className="gatsby-image" />
      </div>
    </div>
  )
}

export default Feature

export const featureQuery = graphql`
  fragment Feature on WpPage {
    feature {
      ctaText
      ctaUrl
      title
      image {
        gatsbyImage(
          width: 960
          height: 960
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 80
        )
      }
    }
  }
`
