import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import Seo from '../components/Seo'
import Feature from '../components/Feature'
import Canonical from '../components/Canonical'

const Home = ({ data }) => {
  const { page } = data
  const { feature } = page
  // const siteTitle = data.site.siteMetadata.title
  return (
    <Layout>
      <Seo title={page.title} />
      <Canonical slug={page.slug} />
      <Feature data={feature} />
      <div dangerouslySetInnerHTML={{ __html: page.content }} />
    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query {
    page: wpPage(title: { eq: "home" }) {
      id
      slug
      content
      title
      ...Feature
    }
  }
`
